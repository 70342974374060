import React from "react"
import { graphql } from "gatsby"
import { FormattedMessage, Link as IntlLink } from "gatsby-plugin-intl";

import Layout from "../components/layout"
import SEO from "../components/seo"

import './post.css';

export default function Template({ data }) {
  const { markdownRemark } = data // data.markdownRemark holds your post data
  const { frontmatter, html } = markdownRemark
  return (
    <Layout hero={frontmatter.hero}>
      <SEO title={frontmatter.title}/>
      <div
        className="post-content"
        dangerouslySetInnerHTML={{ __html: html }}
      />
      <div className="cta">
        <IntlLink
          className="cta-button"
          to="/contact"
        >
          <FormattedMessage
            id="post-contact-cta"
          />
        </IntlLink>
      </div>
    </Layout>
  )
}
export const pageQuery = graphql`
  query($type: String!, $slug: String!, $source_language: String!) {
    markdownRemark(frontmatter: {
      slug: { eq: $slug }
      type: { eq: $type }
      source_language: { eq: $source_language }
    }) {
      html
      frontmatter {
        type
        title
        hero {
          tagline
          image {
            childImageSharp {
              fluid(maxWidth: 1920) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          image_attribution {user, name}
        }
      }
    }
  }
`
